export enum CodeOfConductPrincipleCategory {
  PAID_WAGE = 'PAID_WAGE',
  WORKING_CONDITIONS = 'WORKING_CONDITIONS',
  NO_FORCED_LABOUR = 'NO_FORCED_LABOUR',
  NO_CHILD_LABOUR = 'NO_CHILD_LABOUR',
  WORKING_HOURS_COMPLIANCE = 'WORKING_HOURS_COMPLIANCE',
  NO_DISCRIMINATION = 'NO_DISCRIMINATION',
  REGULAR_EMPLOYMENT = 'REGULAR_EMPLOYMENT',
  FREEDOM_OF_ASSOCIATION = 'FREEDOM_OF_ASSOCIATION',
  RESPECTFUL_TREATMENT = 'RESPECTFUL_TREATMENT',
  ENVIRONMENT_PROTECTION = 'ENVIRONMENT_PROTECTION',
  ETHICAL_BUSINESS_PRACTISES = 'ETHICAL_BUSINESS_PRACTISES',
  ECONOMIC_EMPOWERMENT = 'ECONOMIC_EMPOWERMENT',
  HEALTH_AND_SAFETY = 'HEALTH_AND_SAFETY',
  AUTONOMY_AND_INCLUSION = 'AUTONOMY_AND_INCLUSION',
  COLLECTIVE_REPRESENTATION = 'COLLECTIVE_REPRESENTATION',
  GENDER_EQUALITY = 'GENDER_EQUALITY'
}

export interface CodeOfConductPrincipleDto {
  uuid: string
  category: CodeOfConductPrincipleCategory
  description: string
  paragraph: number
  createdAt: string
}
