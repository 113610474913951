import { FormControlLabel, Radio } from '@material-ui/core'
import React from 'react'
import { getCurrentUser } from '../user'
import { MaterialType } from './material-type.types'

export const CollectedMaterialTypes = Object.freeze({
  PET: 'PET',
  PET_NATURAL: 'PET_NATURAL',
  PET_BLUE: 'PET_BLUE',
  PET_GREEN: 'PET_GREEN',
  PET_BROWN: 'PET_BROWN',
  PET_WHITE: 'PET_WHITE',
  PET_BLUISH: 'PET_BLUISH',
  HDPE: 'HDPE',
  HDPE_BLACK: 'HDPE_BLACK',
  HDPE_RED: 'HDPE_RED',
  HDPE_WHITE: 'HDPE_WHITE',
  LDPE: 'LDPE',
  PP: 'PP',
  PP_CUP_MIX: 'PP_CUP_MIX',
  PP_CUP_PRINT: 'PP_CUP_PRINT',
  PS: 'PS',
  PE: 'PE',
  PVC: 'PVC',
  PLASTIC_WASTE: 'PLASTIC_WASTE',
  FLEXIBLE_PLASTIC: 'FLEXIBLE_PLASTIC',
  FLEXIBLE_PLASTIC_TRANSPARENT_WHITE: 'FLEXIBLE_PLASTIC_TRANSPARENT_WHITE',
  TETRAPAK: 'TETRAPAK',
  MIXED: 'MIXED',
  PAPER_MAGAZINE: 'PAPER_MAGAZINE',
  PAPER_NEWSPRINT: 'PAPER_NEWSPRINT',

  PP_WHITE: 'PP_WHITE',
  PP_TRANSPARENT: 'PP_TRANSPARENT',
  FISHING_NET: 'FISHING_NET',
  BOTTLE_CAP: 'BOTTLE_CAP',
  BOTTLE_CAP_BLUE: 'BOTTLE_CAP_BLUE',
  BOTTLE_CAP_COLOURED: 'BOTTLE_CAP_COLOURED',
  BROKEN_PLASTIC: 'BROKEN_PLASTIC',

  METAL_ALUMINIUM: 'METAL_ALUMINIUM',
  METAL_BRASS: 'METAL_BRASS',
  METAL_COPPER: 'METAL_COPPER',
  METAL_STEEL: 'METAL_STEEL',
  METAL_SHEET_METAL: 'METAL_SHEET_METAL',

  RIGID_PLASTIC: 'RIGID_PLASTIC',
  CARDBOARD_AND_PAPER: 'CARDBOARD_AND_PAPER',
  GLASS: 'GLASS',
  GLASS_GREEN: 'GLASS_GREEN',
  GLASS_AMBER: 'GLASS_AMBER',
  GLASS_TRANSPARENT: 'GLASS_TRANSPARENT',
  GLASS_MIXED: 'GLASS_MIXED',

  CLOTHING_AND_FOOTWEAR: 'CLOTHING_AND_FOOTWEAR',
  METAL: 'METAL',
  BATTERIES: 'BATTERIES',
  E_WASTE: 'E_WASTE',
  RESIDUE: 'RESIDUE',
  SANITARY_WASTE: 'SANITARY_WASTE',
  OIL: 'OIL',
  ORGANIC_WASTE: 'ORGANIC_WASTE',
  RUBBER: 'RUBBER',
  CLOTH_WASTE: 'CLOTH_WASTE',
  FOOTWEAR_WASTE: 'FOOTWEAR_WASTE',
  MLP: 'MLP',
  WOOD_MIXED: 'WOOD_MIXED',
  CLOTH_COTTON: 'CLOTH_COTTON',
  CLOTH_COTTON_WHITE: 'CLOTH_COTTON_WHITE',
  CLOTH_COTTON_COLOUR: 'CLOTH_COTTON_COLOUR',
  CLOTH_POLYESTER: 'CLOTH_POLYESTER',
  CLOTH_OTHER: 'CLOTH_OTHER',
  MIXED_DRY: 'MIXED_DRY',
  RDF: 'RDF'
})

export function getAllMaterialTypeOptions() {
  return Object.keys(MaterialType).map((materialType) => ({
    value: materialType,
    label: materialType
  }))
}

export function getCollectedMaterialTypeOptions(t) {
  const collectedMaterialTypes = getCurrentUser().collectedMaterialTypes || []
  const collectedMaterialTypesSet = new Set(collectedMaterialTypes)

  return Object.keys(CollectedMaterialTypes)
    .filter(
      (collectedMaterialType) =>
        collectedMaterialTypes.length === 0 ||
        collectedMaterialTypesSet.has(collectedMaterialType)
    )
    .map((collectedMaterialType) => (
      <FormControlLabel
        key={collectedMaterialType}
        value={collectedMaterialType}
        control={<Radio />}
        label={t(`materialType_${collectedMaterialType}`)}
      />
    ))
}

export function getAllCollectedMaterialTypeOptions() {
  return Object.keys(CollectedMaterialTypes).map((materialType) => ({
    value: materialType,
    label: materialType
  }))
}

export function getMaterialTypeOptions(materialTypes, t) {
  return materialTypes.map((materialType) => (
    <FormControlLabel
      key={materialType}
      value={materialType}
      control={<Radio />}
      label={t(`materialType_${materialType}`)}
    />
  ))
}

export function getSegregationOutputMaterialTypeOptions(t) {
  const segregationOutputMaterialTypes =
    getCurrentUser().segregationOutputMaterialTypes || []
  const segregationOutputMaterialTypesSet = new Set(
    segregationOutputMaterialTypes
  )

  return Object.keys(MaterialType)
    .filter(
      (materialType) =>
        segregationOutputMaterialTypesSet.length === 0 ||
        segregationOutputMaterialTypesSet.has(materialType)
    )
    .map((materialType) => (
      <FormControlLabel
        key={materialType}
        value={materialType}
        control={<Radio />}
        label={t(`materialType_${materialType}`)}
      />
    ))
}
